import { useState, useEffect, useRef } from "react";

import { FcServices } from "react-icons/fc";
import "../App.css";
import { giveFeedback } from "../api/homepage/feedback";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import fullcircle from "../images/fullcircle.png"
// import halfcircle from "../images/halfcircle.png"
// import why_choose_us from "../images/why_choose_us.png"

import { motion } from "framer-motion";

const AnimatedTitle = ({ title, color }) => {
  const [animatedText, setAnimatedText] = useState("");

  useEffect(() => {
    let index = 0;

    const interval = setInterval(() => {
      setAnimatedText(title.substring(0, index));
      index = (index + 1) % (title.length + 1);

      if (index === 0 && title.length === 7) {
        clearInterval(interval);
      }
    }, 400);

    return () => clearInterval(interval);
  }, [title]);

  return (
    <span className="animated-title" style={{ color }}>
      {animatedText}
    </span>
  );
};

const AchievementCard = ({ data }) => {
  const [displayValue, setDisplayValue] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const achievementRef = useRef();
  const timeoutRef = useRef();

  const debounce = (func, delay) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(func, delay);
  };

  const handleScroll = () => {
    debounce(() => {
      const rect = achievementRef.current.getBoundingClientRect();
      const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

      if (isVisible && !hasStarted) {
        startCount();
      } else if (!isVisible && hasStarted) {
        resetCount();
      }
    }, 200);
  };

  const startCount = () => {
    const startValue = data.title === "Projects Delivered" ? 1 : 0;
    const endValue =
      data.title === "Projects Delivered"
        ? data.data
        : parseFloat(data.data) * 1000;
    const duration = 5000;
    const increment = (endValue - startValue) / (duration / 200);

    let currentValue = startValue;
    let interval;

    const updateValue = () => {
      setDisplayValue((prevValue) => {
        currentValue += increment;

        if (currentValue >= endValue) {
          currentValue = endValue;
          clearInterval(interval);
          setHasStarted(false);
        }

        return Math.round(currentValue);
      });
    };

    interval = setInterval(updateValue, 100);
    setHasStarted(true);
  };

  const resetCount = () => {
    setDisplayValue(0);
    setHasStarted(false);
  };

  const formatValue = (value) => {
    if (value >= 1000) {
      return `${value / 1000}K`;
    }
    return value;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearInterval(timeoutRef.current);
    };
  }, [handleScroll]);

  return (
    <div className="achievements_data_card" ref={achievementRef}>
      <div className="achievements_data_card_data">
        {formatValue(displayValue)}
      </div>
      <div className="achievements_data_card_title">{data.title}</div>
    </div>
  );
};

const PopupDemoForm = ({ onClose }) => {
  const [isEmpty, setIsEmpty] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    contact_number: "",
    service: "",
    email: "",
    message: "",
    from: "algowiz",
    textareaCount: 0,
  });
  const [errors, setErrors] = useState({
    nameError: "",
    emailError: "",
    contact_numberError: "",
    messageError: "",
  });

  const popupRef = useRef();

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleInputChange = (event, fieldName, maxChars = Infinity) => {
    const inputValue = event.target.value;
    if (inputValue.length > maxChars) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: inputValue,
    }));

    if (fieldName === "name" && inputValue.length >= 3) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`${fieldName}Error`]: "",
      }));
    } else if (fieldName === "message" && inputValue.length > 10) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`${fieldName}Error`]: "",
      }));
    } else if (fieldName === "contact_number" && /^\d{10}$/.test(inputValue)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`${fieldName}Error`]: "",
      }));
    } else if (
      fieldName === "email" &&
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`${fieldName}Error`]: "",
      }));
    } else if (fieldName === "service" && inputValue.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`${fieldName}Error`]: "",
      }));
    }

    if (inputValue.length <= maxChars) {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: inputValue,
        textareaCount: inputValue.length,
      }));
    }
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (formData.name.trim().length < 3) {
      newErrors.nameError = "should contain at least 3 characters";
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email.trim())) {
      newErrors.emailError = "Invalid email";
      valid = false;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.contact_number.trim())) {
      newErrors.contact_numberError =
        "Invalid phone number (10 digits required)";
      valid = false;
    }

    if (formData.message.trim() === "") {
      newErrors.messageError = "minimum 10 characters needed";
      valid = false;
    }

    if (formData.service.trim() === "") {
      newErrors.serviceError = "Please select a service";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      if (formData === "") {
        setIsEmpty(true);
      } else {
        const response = await giveFeedback(formData);
        console.log("response", response);
        if (response.status === "success") {
          // Show success toast
          toast.success(response.message, {
            position: "top-right",
            autoClose: 3000,
          });

          // const mobileNumber = "919380378810";
          // const whatsappUrl = `https://api.whatsapp.com/send?phone=${mobileNumber}&text=${encodeURIComponent(
          //   formData
          // )}`;
          // window.open(whatsappUrl, "_blank");
        }
        setFormData("");
      }

      onClose();
    }
  };

  return (
    <div className="popup-demo-container" ref={popupRef}>
      <div className="popup-demo-container-header">
        <div className="popup-demo-container-headerTitleAndIcon">
          <div className="popup-demo-container-headerIcon">
            <FcServices className="popup-demo-container-headerIcon" />
          </div>
          <div className="popup-demo-container-headerTitle">
            Service requirement form
          </div>
        </div>
        <button className="popup-demo-close" onClick={onClose}>
          x
        </button>
      </div>
      <div className="popup-demo-container-divider"></div>
      <div className="popup-demo-container-form-title">
        Please fill the below :
      </div>
      <form className="popup-demo-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div>
            <label
              htmlFor="name"
              initial={{ opacity: 0, x: -10 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your name"
              onChange={(e) => handleInputChange(e, "name")}
            />
            {errors.nameError && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="name_error_message"
              >
                {errors.nameError}
              </motion.div>
            )}
          </div>
          <div>
            <label htmlFor="service">Required Service</label>
            <select
              id="service"
              name="service"
              onChange={(e) => handleInputChange(e, "service")}
            >
              <option value="">Select an option</option>
              <option value="Trading automation support">
                White-Label Automated Trading Platforms
              </option>
              <option value="Copy Trading">Copy Trading</option>
              <option value="Custom Automation Platform">
                Custom Trading Automation
              </option>
              <option value="Other">Other</option>
            </select>
            {errors.serviceError && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="service_error_message"
              >
                {errors.serviceError}
              </motion.div>
            )}
          </div>
        </div>
        <div className="form-row">
          <div>
            <label htmlFor="email">Email Id</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              onChange={(e) => handleInputChange(e, "email")}
            />
            {errors.emailError && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="email_error_message"
              >
                {errors.emailError}
              </motion.div>
            )}
          </div>
          <div>
            <label htmlFor="summary">Summary</label>
            <textarea
              id="summary"
              name="summary"
              placeholder="Enter a summary"
              value={formData.summary}
              onChange={(e) => handleInputChange(e, "message", 250)}
            ></textarea>
            {errors.messageError && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="summary_error_message"
              >
                {errors.messageError}
              </motion.div>
            )}
            <div className="textarea-count">{formData.textareaCount}/250</div>
          </div>
        </div>

        <div className="form-row">
          <div className="form-row-phone">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
              onChange={(e) => handleInputChange(e, "contact_number")}
              onKeyPress={(e) => {
                const isNumeric = /^[0-9]*$/;
                if (!isNumeric.test(e.key)) {
                  e.preventDefault();
                }
              }}
            />
            {errors.contact_numberError && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="phone_error_message"
              >
                {errors.contact_numberError}
              </motion.div>
            )}
          </div>
        </div>
        <div className="form-row">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

const TestimonialCarousel = ({ testimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.data.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + testimonials.data.length) % testimonials.data.length
    );
  };

  const cardContainerStyle = {
    transform: `translateX(-${
      currentIndex * (100 / testimonials.data.length)
    }%)`,
    transition: "transform 1s ease-in-out",
  };

  return (
    <div className="testimonial_carousel_wrapper">
      <div className="testimonial_card_container" style={cardContainerStyle}>
        {testimonials.data?.map((data, index) => (
          <div
            key={index}
            className={`testimonials_card ${
              index === currentIndex ? "active" : ""
            }`}
          >
            <div className="testimonials_card_image_container">
              <div className="testimonials_card_image">{data.image}</div>
            </div>

            <div className="testimonials_card_dataContainer">
              <div className="testimonials_card_name">{data.userName}</div>
              <div className="testimonials_card_feedback">{data.feedback}</div>
            </div>
          </div>
        ))}
      </div>

      <button onClick={prevSlide} className="carousel_button prev">
        Prev
      </button>
      <button onClick={nextSlide} className="carousel_button next">
        Next
      </button>
    </div>
  );
};

const PageSection = ({ sectionData }) => {
  const {
    headerData,
    homePageInformation,
    services,
    achievements,
    about,
    testimonials,
    footerInformation,
    footer,
    copyright,
  } = sectionData || {};

  const [activeLink, setActiveLink] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [hoveredDemo, setHoveredDemo] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const aboutSectionRef = useRef(null);
  const animatedElementsRef = useRef(false);

  useEffect(() => {
    const handleScroll = () => {
      const rect = aboutSectionRef.current.getBoundingClientRect();
      const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

      if (isVisible && !animatedElementsRef.current) {
        animateElements();
        animatedElementsRef.current = true;
      } else if (!isVisible && animatedElementsRef.current) {
        animatedElementsRef.current = false;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const animateElements = () => {
    const handbulbElement = document.querySelector(".handbulb");
    const handshakeElement = document.querySelector(".handshake");
    const aboutCardElements = document.querySelectorAll(".about_card");

    if (handbulbElement && handshakeElement) {
      handbulbElement.classList.add("move-left");
      handshakeElement.classList.add("move-right");

      setTimeout(() => {
        handbulbElement.classList.remove("move-left");
        handbulbElement.classList.add("move-final");
        handshakeElement.classList.remove("move-right");
        handshakeElement.classList.add("move-final");
      }, 2000);
    }

    if (aboutCardElements) {
      aboutCardElements.forEach((card, index) => {
        const childDataElement = card.querySelector(".about_card_childData");
        if (
          childDataElement &&
          !childDataElement.classList.contains("animated")
        ) {
          childDataElement.style.opacity = 0;
          childDataElement.classList.add("animated");

          setTimeout(() => {
            childDataElement.style.opacity = 1;
          }, 3500);
        }
      });
    }
  };

  const handleScheduleButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const scrollToSection = (sectionUrl) => {
    setActiveLink(sectionUrl);
    const section = document.getElementById(sectionUrl);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", duration: "200" });
    }
  };

  // let lastMoveTime = 0;

  // function moveCursor(e) {
  //   const mainCursor = document.querySelector('.cursor');
  //   const cursorTrail = document.querySelector('.cursor-trail');

  //   const mouseX = e.clientX - mainCursor.offsetWidth / 2;
  //   const mouseY = e.clientY - mainCursor.offsetHeight / 2;

  //   mainCursor.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
  //   cursorTrail.style.left = mouseX + 'px';
  //   cursorTrail.style.top = mouseY + 'px';

  //   const currentTime = new Date().getTime();
  //   const timeDifference = currentTime - lastMoveTime;

  //   if (timeDifference < 10) {
  //     cursorTrail.style.opacity = 1;

  //     setTimeout(() => {
  //       cursorTrail.style.opacity = 0;
  //     }, 100);
  //   }

  //   lastMoveTime = currentTime;
  // }

  // document.addEventListener('mousemove', moveCursor);
  return (
    <div className="homepage_container">
      <ToastContainer />
      {/* <div className="cursor"></div>
      <div class="cursor-trail"></div> */}

      {/* start of header */}
      {headerData && (
        <div className="header_main_container">
          <div className="header_logoAndName_conainer">
            <div className="header_logo_conainer">
              <img src={headerData.logo} alt="algowiz_logo" />
            </div>
            <div className="header_name_conainer">
              <span>Algo</span>
              <span>wiz</span>
            </div>
          </div>
          <div className="header_navigation_container">
            {headerData.links?.map((link, index) => {
              return (
                <div key={index} className="header_navigation_urls">
                  <span
                    onClick={() => scrollToSection(link.url)}
                    style={{
                      color: link.url === activeLink ? "#818194" : "#818194",
                    }}
                  >
                    {link.label}
                  </span>
                </div>
              );
            })}
            <div className="header_navigation_contactUs">
              <div className="header_navigation_verticalLine"></div>
              <button
                className="header_navigation_contactUsButton"
                onClick={(e) => {
                  e.stopPropagation();
                  handleScheduleButtonClick();
                }}
              >
                <i
                  className={`headset-logo ${!hovered ? "rotate360" : ""}`}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  {headerData.contactButton.contactLogo}
                </i>{" "}
                {headerData.contactButton.label}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* end of header */}
      {/* start of  homePageInformation*/}
      {homePageInformation && (
        <div className="homePageInformation_main_container">
          <div className="homePageInformation_title">
            Welcome to <AnimatedTitle title="Algowiz" color="#E65C00" />
          </div>
          <div className="homePageInformation_subtitle">
            {homePageInformation.subtitle}
          </div>
          <div className="homePageInformation_info">
            {homePageInformation.info}
          </div>
          <button
            className="homePageInformation_button"
            onClick={(e) => {
              e.stopPropagation();
              handleScheduleButtonClick();
            }}
            onMouseEnter={() => setHoveredDemo(true)}
            onMouseLeave={() => setHoveredDemo(false)}
          >
            {homePageInformation.scheduleButton.label}
            <i>
              {hoveredDemo
                ? homePageInformation.scheduleButton.icon2
                : homePageInformation.scheduleButton.icon1}
            </i>
          </button>
          {showPopup && (
            <div className="popup_container">
              <PopupDemoForm onClose={handlePopupClose} />
            </div>
          )}
        </div>
      )}
      {/* end of  homePageInformation*/}

      {/* start of services */}
      {services && (
        <div id="services" className="services_main_container">
          <div className="services_title">{services.title}</div>
          <div className="services_card_container">
            {services.typeOfService?.map((service, index) => {
              return (
                <div key={index} className="services_card">
                  <div
                    className={
                      index === 0
                        ? "services_card_icon1"
                        : "services_card_icon2"
                    }
                  >
                    <i>{service.icon}</i>
                  </div>
                  <div
                    className={
                      index === 0
                        ? "services_card_title1"
                        : "services_card_title2"
                    }
                  >
                    {service.title}
                    <div
                      className={
                        index === 0
                          ? "services_card_title1_line"
                          : "services_card_title2_line"
                      }
                    ></div>
                  </div>
                  <div className="services_card_content">{service.content}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* end of services */}
      {/* start of achievements */}
      {achievements && (
        <div id="achievements" className="achievements_main_container">
          <div className="achievements_titles">
            <div className="achievements_main_title">{achievements.title}</div>
            <div className="achievements_subtitle">{achievements.subtitle}</div>
          </div>
          <div className="achievements_data">
            {achievements.achievementData.map((data, index) => {
              return <AchievementCard key={index} data={data} />;
            })}
          </div>
        </div>
      )}
      {/* end of achievements */}
      {/* start of about */}
      {about && (
        <div id="about" className="about_main_container" ref={aboutSectionRef}>
          {
            <div className="about_card_container">
              {about.data?.map((data, index) => {
                return (
                  <div key={index} className="about_card">
                    {console.log(data)}
                    {/*                     
                        <div style={{ display: (index === 0 || index === 2) && "none" }}
                          className={index === 0 || index === 2 ? "" : "whyAlgoImageStyle"}
                        >
                        </div>
                        <div>
                          <img src={data.gif} className={index === 0 ?
                            "handbulb" : index === 1 ? "handshake" : "arrowTarget"} />
                        </div> */}
                    {index === 1 && (
                      <div className="circleContainer">
                        <img src={data.halfcircle} className="halfcircle" />
                        <img src={data.fullcircle} className="fullcircle" />
                        <img
                          src={data.why_choose_us}
                          className="why_choose_us"
                        />
                      </div>
                    )}
                    {index === 1 && (
                      <div className="animatedGifs">
                        <img src={data.gif1} className="handbulb" />
                        <img src={data.gif} className="arrowTarget" />
                        <img src={data.gif2} className="handshake" />
                      </div>
                    )}
                    <div className="about_card_childData">
                      <div className="about_card_title">{data.title}</div>
                      <div className="about_card_content">{data.content}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        </div>
      )}
      {/* end of about */}
      {/* start of testimonials */}
      {/* { */}
      {/* // testimonials && (
                  
                //     <div id="testimonials" className="testimonials_main_container">
                //         <div className="testimonial_title">
                //             {testimonials.title}
                //         </div>
                        
                //         <TestimonialCarousel testimonials={ testimonials } /> */}
      {/* <div className="testimonial_card_container">
                            {
                              
                                testimonials.data?.map((data, index) => {
                                  
                                      
                                   return  <div key={index} className="testimonials_card">
                                            <div className="testimonials_card_image_container">
                                                <div className="testimonials_card_image">{data.image}</div>
                                            </div>

                                            <div className="testimonials_card_dataContainer">
                                                <div className="testimonials_card_name">{data.userName}</div>
                                                <div className="testimonials_card_feedback">{data.feedback}</div>
                                            </div>
                                        </div>
                                      
                                   
                                })
                            }
                           
                           
                         
                        </div> */}

      {/* //     </div> */}
      {/* )} */}
      {/* end of testimonials */}
      {/* start of footerInformation */}
      {footerInformation && (
        <div className="footerInformation_main_container">
          <div className="footerInformation_title">
            {footerInformation.title}
          </div>
          <div className="footerInformation_subtitle">
            {footerInformation.subtitle}
          </div>

          <button
            className="footerInformation_button"
            onClick={(e) => {
              e.stopPropagation();
              handleScheduleButtonClick();
            }}
          >
            {footerInformation.scheduleButton.label}
          </button>
        </div>
      )}
      {/* end of footerInformation */}
      {/* start of footer */}
      {footer && (
        <div className="footer_main_container">
          <div className="footerLinks">
            <div className="footer_logo">
              <img src={footer.logo} alt="footerLogo" />
              <p>{footer.logoText}</p>
            </div>
            {footer.footerLinks?.map((link, index) => {
              return (
                <div key={index} className="footerLinks_card">
                  <div className="footerLinks_card_title">{link.title}</div>
                  <div className="footerLinks_card_links">
                    {link.links?.map((l, ind) => {
                      return (
                        <a
                          key={ind}
                          className="footerLinks_card_singleLink"
                          href={
                            l.url.startsWith("mailto:") ||
                            l.url.startsWith("tel:")
                              ? l.url
                              : null
                          }
                          onClick={(e) => {
                            if (
                              !(
                                l.url.startsWith("mailto:") ||
                                l.url.startsWith("tel:")
                              )
                            ) {
                              if (l.label !== "Schedule a demo") {
                                scrollToSection(l.url);
                              } else {
                                e.stopPropagation();
                                handleScheduleButtonClick();
                              }
                            }
                          }}
                        >
                          {l.icon} {l.label}
                        </a>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {/* end of footer */}
      {copyright && <div className="copyright_text">{copyright.title}</div>}
    </div>
  );
};

export default PageSection;
