import { postRequest } from "../requestMethods"

export const giveFeedback = async (message) => {
    console.log("inside feedback")
    console.log(message)
  const response = await postRequest("/api/v1/query/contactUs", 
    message,
  );
  return response; 
};
