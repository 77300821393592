import axios from "axios";

const environment = process.env.REACT_APP_ENVIRONMENT;
const headers = {};
console.log(process.env.REACT_APP_HOST_URL)
const client = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
  headers: headers,
});

export default client;
