import client from "./client"; 

export const getRequest = async (endPoint) => {
    try {
        const res = await client.get(endPoint);
        return res.data;
    } catch (error) {
        const { response } = error;
        if (response?.data) {
            return response.data;
        }
        return { error: error.message || error };
    }
};

export const postRequest = async (endPoint, data) => {
    console.log(data)
   
    try {
        const res = await client.post(endPoint, data);
        console.log("res",res)
        return res.data;
    } catch (error) {
        const { response } = error;
        if (response?.data) {
            return response.data;
        }
        return { error: error.message || error };
    }
};

export const patchRequest = async (endPoint, data) => {
    try {
        const res = await client.patch(endPoint, data);
        return res.data;
    } catch (error) {
        const { response } = error;
        if (response?.data) {
            return response.data;
        }
        return { error: error.message || error };
    }
};

export const deleteRequest = async (endPoint, data) => {
    try {
        const res = await client.delete(endPoint, data);
        return res.data;
    } catch (error) {
        const { response } = error;
        if (response?.data) {
            return response.data;
        }
        return { error: error.message || error };
    }
};
