import PageSection from "./Components/PageSection";
import algowiz_logo from "./images/algowiz_logo.svg"
import { ImHeadphones } from "react-icons/im";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FiArrowRight } from "react-icons/fi";
import { FcServices } from "react-icons/fc";
import { FcComboChart } from "react-icons/fc";
import { RiUser3Fill } from "react-icons/ri";
import { HiOutlineMail } from "react-icons/hi";
import { SlPhone } from "react-icons/sl";
import boost from "./images/boost.gif"
import customize from "./images/customize.gif"
import simplify from "./images/simplify.gif"
import fullcircle from "./images/fullcircle.png"
import halfcircle from "./images/halfcircle.png"
import why_choose_us from "./images/why_choose_us.png"

function App() {

  const headerData = {
    logo: algowiz_logo,
    website_name: "Algowiz",
    links: [
      { label: "Services", url: "services" },
      { label: "Our Achievements", url: "achievements" },
      { label: "About us", url: "about" },
    ],
    contactButton: {
      contactLogo: <ImHeadphones />,
      label: "Contact us",
      url: "/contact"
    }
  }

  const homePageInformation = {
    title: "Welcome to Algowiz",
    subtitle: "Your Partner in innovation",
    info: "Elevate your trading with our innovative platform, where creativity and technology converge to transform your strategies into reality. Dive into a world of endless possibilities and seamless trading experiences.",
    scheduleButton: {
      label: "Schedule demo",
      icon1: <MdOutlineKeyboardArrowRight />,
      icon2: <FiArrowRight />,
      url: "contact"
    }
  }

  const services = {
    title: `Beyond Solutions, 
    Strategizing for Your Business Growth.`,
    typeOfService: [
      {
        icon: <FcServices className="services_icon" />,
        title: "White-Label Automated Trading Platforms",
        content: `Design, develop, and deploy high-performance options trading platforms that deliver on your unique user needs`
      },
      {
        icon: <FcComboChart className="services_icon" />,
        title: "Copy Trading",
        content: `Craft a trading platform that reflects your vision and workflow, perfectly aligned with your trading style and strategies.
        `
      },
    ]

  }

  const achievements = {
    title: `Our Achievements So Far!`,
    subtitle: `Achieving the best possible numbers for you every day!`,
    achievementData: [
      {
        title: "Users",
        data: "25K"
      },
      {
        title: "Orders Executed",
        data: "500K"
      },
      {
        title: "Projects Delivered",
        data: "11"
      },
    ]
  }

  const about = {
    title: "Why Choose Algowiz",
    data: [
      {
        index: "01",
        title: "Boost",
        content: "Achieve peak performance with automated strategies that execute faster and more consistently than manual trading."
      },
      {
        index: "03",
        title: "Customize",
        gif:simplify,
        gif1:boost,
        gif2:customize,
        content: "Tailor your trading experience with options platforms, copy trading options, and custom platforms built to your exact specifications.",
        fullcircle:fullcircle,
        halfcircle:halfcircle,
        why_choose_us:why_choose_us
      },
      {
        index: "02",
        title: "Simplify",
        content: "Focus on what matters most while AlgoWiz handles the heavy lifting of trade execution and management."
      },
     
    ]
  }

  const testimonials = {
    title: "Some testimonials from our clients",
    data: [
      {
        image: <RiUser3Fill className="testimonial_user_image" />,
        userName: "Vignesh",
        feedback: `I had the chance to work with Algowiz on app projects in entertainment as well as a web platform in entertainment too! Always attentive, proactive, rigorous, the result is beyond expectations every time.
          And also super nice ;)`

      },
      {
        image: <RiUser3Fill className="testimonial_user_image" />,
        userName: "Akash",
        feedback: `I had the chance to work with Algowiz on app projects in entertainment as well as a web platform in entertainment too! Always attentive, proactive, rigorous, the result is beyond expectations every time.
        And also super nice ;)
        I had the chance to work with Algowiz on app projects in entertainment as well as a web platform in entertainment too!
        `

      },
      {
        image: <RiUser3Fill className="testimonial_user_image" />,
        userName: "Ega",
        feedback: `I had the chance to work with Algowiz on app projects in entertainment as well as a web platform in entertainment too! Always attentive, proactive, rigorous, the result is beyond expectations every time.
      I had the chance to work with Algowiz on app projects in entertainment as well as a 
      `

      },
    ]
  }

  const footerInformation = {
    title: "What are you waiting for?",
    subtitle: `Ready to revolutionize your trading experience. Contact us today
     for a personalized consultation.`,
    scheduleButton: {
      label: "Schedule a demo",
      url: "contact"
    }
  }
  const footer = {
    logo: algowiz_logo,
    logoText:`POWERED BY ALGOWIZ
TECHNOLOGIES PVT LTD
    `,
    footerLinks:[
      {
        title:"Feature",
        links:[
          {
            label:"About us",
            url:"about"
          },
          {
            label:"Services",
            url:"services"
          },
          // {
          //   // label:"Achievement",
          //   // url:"achievements"
          // },
        ]
      },
      {
        title:"Extras",
        links:[
          {
            label:"Schedule a demo",
            url:"contact"
          },
          {
            // label:"Testimonials",
            // url:"testimonials"
            label:"Achievement",
            url:"achievements"
          }
        ]
      },
      // {
      //   title:"Legal",
      //   links:[
      //     {
      //       label:"Terms & Conditions",
      //       url:"contact"
      //     },
      //     {
      //       label:"Privacy & Policy",
      //       url:"testimonials"
      //     }
      //   ]
      // },
      {
        title:"Contact",
        links:[
          {
            label:"support@algowiz.in",
            url:"mailto:support@algowiz.in",
            icon:<HiOutlineMail className="footer_icon_style"/>
          },
          {
            label:"+91 87888 41459",
            url:"tel:+918788841459",
            icon:<SlPhone className="footer_icon_style"/>
          }
        ]
      },
    ]
  }
  const copyright={
    title:'COPYRIGHT ALL RIGHTS RESERVED @2024'
  }
  return (
    <div className="App">
      <PageSection sectionData={{ headerData, homePageInformation, services, achievements, about, testimonials, footerInformation,footer, copyright }} />
    </div>
  );
}

export default App;
